<template>
  <div></div>
</template>

<script>
export default {
  mounted () {
    this.$store.dispatch('removeToken')
    this.$router.push({ name: 'ww.login' })
  }
}
</script>
